<template>
  <div>
    <div class="container">
      <ais-instant-search
        :search-client="searchClient"
        index-name="dadopoulos_products_simple"
      >
        <ais-configure :hits-per-page.camel="9" />
        <div class="search-panel">
          <div class="search-panel__filters" :class="{ [isActive]: true }">
            <div class="sort">
              <ais-sort-by
                :items="[
                  /* eslint-disable */
                  {label: 'Αύξουσα Τιμή', value: 'tasoulisnew_products/sort/price:asc'},
                  {label: 'Φθίνουσα Τιμή', value: 'tasoulisnew_products/sort/price:desc'}
                ]"
              />
            </div>
          </div>
          <div class="search-panel__results">
                <ais-autocomplete class="el">
        <template v-slot="{ indices }">
          <div class="test-sea">
            <ais-search-box
              id="autocomplete"
              placeholder="Αναζήτηση..."
              ref="targetElement"
            />
            <button class="sear" @click="fco()">
              <img src="/wp-content/uploads/2023/10/search-n1.png" />
            </button>
          </div>
          <ul
            style="background-color: rgb(255 255 255); margin-top: 0px"
            v-for="index in indices"
            :key="index.indexId"
            class="res"
          >
            <li v-if="focus === true">
              <ul class="res1">
                <p v-if="index.hits.length == 0">Κανένα Αποτέλεσμα</p>
                <li
                  v-for="hit in index.hits"
                  :key="hit.id"
                  style="margin-bottom: 20px"
                >
                  <a
                    :href="hit.slug"
                    style="margin-top: 0px; color: black; text-decoration: none; display: flex; gap: 20px; align-items: center;}"
                  >
                  <ais-highlight attribute="name" :hit="hit" />
                    <img
                      style="
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                        background-color: rgb(255 0 0);
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                      "
                      :src="hit.image"
                    />
                    {{ hit.onoma }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </ais-autocomplete>
          </div>
        </div>
      </ais-instant-search>
    </div>
  </div>
  <!-- Mobile button -->
</template>

<script>
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "hibWH0tnEegT9EReYSlNxPQunaYn27GON6zpXp6BCXS4U2V4",
    nodes: [
      {
        host: "search-node1.anvetogroup.com",
        port: "443",
        protocol: "https",
      },
    ],
  },
  additionalSearchParameters: {
    queryBy: "name",
    num_typos: 3,
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  data() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    var catigoria1 = params.get("category");
    var cild = false;
    var focus = false;
    var focus1 = false;
    var test = window.location.href.includes("/en");
    return {
      searchClient,
      isActive: false,
      hover: false,
      catigoria1: catigoria1,
      focus: focus,
      focus1: focus1,
      cild: cild,
      test: test,
      /* eslint-disable */
      btn_img:'/wp-content/uploads/2023/11/filtra.png',
    };
  },
  components: {
    VueSlider,
  },
  methods: {
    /* eslint-disable */
    handleClickOutside(event) {
      const test = event.target;
      const myElement = document.getElementsByClassName("res1");
      console.log(myElement.firstChild);
      if (test.placeholder == "Αναζήτηση...") {
        this.focus = true;
      } else if (test.placeholder == "Search...") {
        this.focus = true;
      } else {
        this.focus = false;
      }
    },
    fco() {
      const input = document.getElementById("autocomplete");
      let firstChild = input.querySelector("input").value;
      console.log(firstChild);
      window.location = "https://dadopoulos.gr/anazitisi/?sr=" + firstChild;
    },
    fco1() {
      const input = document.getElementById("autocomplete");
      let firstChild = input.querySelector("input").value;
      console.log(firstChild);
      window.location =
        "https://dadopoulos.gr/en/anazitisi/?sr=" + firstChild;
    },
    /* Mobile filter button show/hide */
    myFilter: function () {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.btn_img = '/wp-content/uploads/2023/11/x1icon.png';
      } 
      else {
        this.btn_img = '/wp-content/uploads/2023/11/filtra.png';
      }
    },
    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
    /* Go to top after pagination */
    pageChange() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const input = document.getElementById("autocomplete");
    let autocomplete = input.querySelector("input");
    autocomplete.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        window.location =
          "https://tasoulis-jewels1.gr/search/?sr=" + autocomplete.value;
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
@font-face {
  font-family: "Play-Regular";
  src: url("fonts/Play-Regular.woff") format("woff");
}
* {
  font-family: "Play-Regular";
}
#app {
  padding: 50px 20px !important;
}
#app a{
  text-decoration: none;
}
.ais-InstantSearch {
  max-width: 1350px !important;
  gap: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}
.search-panel {
  width: 100% !important;
  display: flex !important;
}
.ais-Hits-list {
  display: flex !important;
  flex-wrap: wrap;
  padding-left: 0px !important;
  justify-content: center;
}
.search-panel__filters {
  display: none;
  width: 22%;
}

.search-panel__results {
  width: 100% !important;
  margin-left: 0% !important;
}
/* Mobile button */
.fil-btn {
  animation-duration: 0.7s;
  display: flex !important;
  position: fixed;
  bottom: 50px !important;
  background: #f26185;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 999;
  display: none !important;
  width: 60px !important;
  height: 60px !important;
}

.fil-btn img {
  width: 40px !important;
  padding: 10px;
}
.filtra-title {
  border-bottom: 1px solid #d9dde5 !important;
  margin-bottom: 20px !important;
}
/**/
/* Search input */
.ais-SearchBox {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.ais-SearchBox-submit {
  right: 1.3rem;
  left: auto;
  background: transparent;
  border:none !important;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0%,-50%);
}
.ais-SearchBox-submit svg{
  width:18px !important;
  height:auto !important;
}
.ais-SearchBox-input{
  width: 100% !important;
  padding: 10px 20px;
  border-radius: 15px;
  outline:none !important;
  background: #ffeaef !important;
  font-size: 16px !important;
  height: 46px;
  height: 46px;
}
.ais-SearchBox-reset, .ais-SearchBox-reset svg, .ais-SearchBox-input[type="search"]::-webkit-search-cancel-button{
  display: none !important;
}
.ais-SearchBox-form{
  width:300px;
  position: relative !important;
}
.ais-SearchBox-input:focus{
  outline:none !important;
}
.ais-SearchBox-input{
  outline:none !important;
  border: 0 !important;
}
/**/

/* Filter inputs */
.ais-RefinementList-item,
.ais-HierarchicalMenu-item {
  position: relative;
}
.ais-RefinementList-label{
  display: flex !important;
  align-items: center !important;
}
.ais-RefinementList-showMore:hover {
  background: transparent !important;
  color: #bd3758 !important;
}
.ais-RefinementList-showMore:focus{
  border: none !important;
  outline: none !important;
}
.ais-RefinementList,
.ais-RangeInput {
  border-bottom: 1px solid #d9dde5 !important;
}

.ais-RangeInput {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px !important;
}

/* Hierarchical input */
.ais-HierarchicalMenu-link {
  text-decoration: none !important;
  color:#222 !important;
}
.ais-HierarchicalMenu-link:hover {
  color:#222 !important;
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list{
  margin-left: 1em !important;
}
.ais-HierarchicalMenu-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
  font-size: 0px;
  position: absolute;
  top: 10px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}
.ais-HierarchicalMenu-label {
  margin-left: 40px;
}
.ais-HierarchicalMenu-item {
  position: relative;
}

.product-link {
  text-decoration: none;
}
/**/
/* Pagination */
.ais-Pagination {
  margin-top: 40px !important;
}
.ais-Pagination-item {
  height: 50px !important;
  border-radius: 0px;
  border: 1px solid #e9e9e9 !important;
  margin-left: 0px !important;
  display: flex !important;
}
.ais-Pagination-link {
  border-radius: 0px;
  padding: 10px;
  color: #f26185;
  height: 50px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex !important;
  align-items: center;
  text-decoration: none !important;
}
.ais-Pagination-list {
  display: flex;
  list-style: none;
  justify-content: center;
}
.ais-Pagination-item:hover a {
  color: #f26185;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #f26185;
  border-color: #f26185;
}
.ais-Pagination-item--selected .ais-Pagination-link:hover {
  color: #fff !important;
}
/**/
/* Products Results */
.card-cont {
  padding-bottom: 10px !important;
}
.hit-authors, .hit-publication-year{
  padding-bottom: 10px !important;
  padding-top: 20px !important;
  color: #000 !important;
}
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
  width: 28% !important;
}

.ais-Hits-item {
  text-align: center;
  margin-bottom: 0px !important;
  border-radius: 20px;
  text-align: center !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ais-Hits-item article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.ais-Hits-item img {
  background: #e3e3e3;
  width: 100% !important;
  height: 280px !important;
  object-fit: contain !important;
  box-shadow: 0 7px 12px rgb(97 45 0 / 21%) !important;
  border-radius: 10px !important;
}

.ais-Hits-item {
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 30px !important;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 7px 12px rgb(97 45 0 / 21%) !important;
}
.ais-Hits-item h1 {
  font-size: 22px !important;
  margin: 0;
  font-weight: 400 !important;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
  color: #222 !important;
}

.ais-Hits-item h1 .ais-Highlight{
  font-size: 22px !important;
  margin: 0;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  margin-top: 15px !important;
  color: #222 !important;
}
.hit-rating {
  font-size: 18px !important;
  color: #da6682;
  font-weight: 700 !important;
  margin-bottom: 15px;
}
.ais-Hits-item h2 {
  font-size: 22px;
  margin: 0;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  margin-top: 15px !important;
}

.ais-Hits-item p {
  font-size: 18px;
  color: #da6682;
  font-weight: 700 !important;
  margin-bottom: 15px;
}
/**/
/* Add to cart */
.card-btn {
  line-height: 60px !important;
  margin-top: auto !important;
}
.algolia-add-cart {
  border-radius: 20px !important;
  background: linear-gradient(
    to bottom right,
    #f26185,
    #f26185,
    #ffbfcf,
    #f26185,
    #f26185
  ) !important;
  padding: 12px 25px !important;
  font-size: 18px !important;
  color: #fff !important;
  text-decoration: none !important;
}

.algolia-add-cart:hover {
  background: linear-gradient(
    to bottom right,
    #c33054,
    #c33054,
    #ffbfcf,
    #c33054,
    #c33054
  ) !important;
}

/* Sort by input */
.ais-SortBy {
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #d9dde5 !important;
}

.ais-SortBy-select:focus {
  border: 1px solid #c4c8d8 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0rem black;
}

.ais-SortBy-select {
  border: 1px solid #c4c8d8 !important;
  font-size: 16px !important;
  border-radius: 2px;
  padding: 10px 20px;
  width: 100%;
  background-color: #ffe6ec;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px 20px;
}
/**/

/* Header styling */
.search-panel h2 {
  font-size: 20px !important;
  font-weight: 400 !important;
}
.filtra-title h2{
  margin-top: 0px;
}
.search-panel h4 {
  font-size: 22px !important;
}
/**/

/* Custom checkboxes */
.ais-RefinementList-list,
.ais-HierarchicalMenu-list {
  list-style: none;
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  padding-left: 0px !important;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ais-RefinementList-labelText,
.ais-HierarchicalMenu-label {
  font-size: 16px;
  margin-left: 40px;
}
/* Hide the browser' s default checkbox */
.ais-RefinementList-list input,
.ais-HierarchicalMenu-list input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.ais-HierarchicalMenu-list {
  padding-bottom: 20px !important;
}

/* Create a custom checkbox */
.ais-RefinementList-count,
.ais-HierarchicalMenu-count {
  font-size: 0px;
  position: absolute;
  top: 10px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}
.ais-Hits-list {
  gap: 20px;
  margin-top: 0px !important;
}

.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
  border: 0px solid !important;
}
.ais-RefinementList-item,
.ais-HierarchicalMenu-item {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ais-Highlight {
  font-size: 18px;
  color: #222 !important;
}

.ais-Highlight,
.ais-RefinementList-count,
.ais-HierarchicalMenu-count {
  cursor: pointer;
}
/* When the checkbox is checked, add a blue background */
.ais-RefinementList-list input:checked ~ .ais-RefinementList-count,
.ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-link--selected
  .ais-HierarchicalMenu-count {
  background-color: #f26185;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ais-RefinementList-count:after,
.ais-HierarchicalMenu-count:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ais-RefinementList-list input:checked ~ .ais-RefinementList-count:after,
.ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-link--selected
  .ais-HierarchicalMenu-count:after {
  display: block;
}

/* Style the checkmark/indicator */
.ais-RefinementList-list .ais-RefinementList-count:after,
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-count:after {
  left: 7px;
  top: 5px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show more link */
.ais-RefinementList-showMore {
  background: transparent !important;
  padding: 0px 0px !important;
  font-size: 17px !important;
  color: #f26185 !important;
  text-decoration: none !important;
  padding-bottom: 20px !important;
  border: none !important;
}
/* slider */
.vue-slider-dot-tooltip-inner {
  border-color: #f26185;
  background-color: #f26185;
}

/* Mobile */
@media only screen and (max-width: 1450px) {
  #app {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1300px) {
  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Hits-item,
  .ais-Results-item {
    width: 40% !important;
  }
}

@media only screen and (max-width: 992px) {
  .ais-RefinementList-item{
    display: flex;
    justify-content: center;
  }
  .ais-SearchBox-form {
    width: 95% !important;
    position: relative !important;
  }
  .ais-Hits-list {
    justify-content: center;
    margin-left: 0px !important;
  }
  .card-btn {
    margin-top: 20px !important;
  }
  #app {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .last {
    margin-bottom: 10px !important;
  }
  .filtra-title {
    margin-top: 40px !important;
  }
  .ais-RefinementList-labelText {
    margin-left: 0px !important;
  }
  .fil-btn {
    display: block !important;
  }
  .ais-Hits-list {
    padding-left: 0px !important;
  }
  .search-panel__filters {
    display: none;
    padding-left: 10%;
    padding-right: 10%;
    width: 80% !important;
    text-align: center !important;
    margin-right: 0px !important;
    position: fixed;
    top: 0;
    background: #fffbfc;
    z-index: 999;
    width: 100%;
    left: 0;
    height: 100%;
    overflow: scroll !important;
    -webkit-animation: slide-in-right 0.7s cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-in-right 0.7s cubic-bezier(.25,.46,.45,.94) both;
  }
  .search-panel__results{
    margin-left: 0px !important;
  }
  @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
  .true {
    display: block !important;
  }

  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Hits-item,
  .ais-Results-item {
    width: 90% !important;
  }

  .ais-Hits-item {
    margin-bottom: 30px !important;
  }
  .ais-InstantSearch .left-panel {
    width: 100%;
    text-align: center !important;
  }

  .ais-InstantSearch .right-panel {
    width: 78%;
    margin-left: auto;
    margin-right: auto;
  }

  .ais-InstantSearch {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    gap: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .ais-SearchBox-form{
    width: 100% !important;
  } 
}
.ais-Autocomplete .ais-SearchBox-submit,
.ais-Autocomplete .ais-SearchBox-reset {
  display: none !important;
}

.sear {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 46px;
  width: 50px;
  z-index: 99999;
  background-color: transparent;
  box-shadow: none;
  border: 0px solid;
  border-top-right-radius: 24px;
  cursor: pointer;
  border-bottom-right-radius: 24px;
}

.sear img {
  width: 50%;
  height: auto;
  filter: invert(14%) sepia(55%) saturate(4392%) hue-rotate(331deg)
    brightness(83%) contrast(95%);
}

.test-sea {
  position: relative;
  width: 300px;
  height:46px !important;
}
.res {
    border-radius: 5px !important;
    /*padding-top: 10px !important;
    padding-bottom: 10px !important;*/
    position: absolute;
    top:1px;
    min-height: 46px !important;
    left: 0px !important;
    margin-left: 0px !important;
    z-index: 5;
    width: 300px;
    background-color: rgb(255 255 255 / 90%) !important;
}
.res a:hover {
  color: #8d1633 !important;
}
.res,
.res ul {
  list-style-type: none;
  padding-left: 0px;
  border-radius: 5px; 
}
.res ul{
  margin-top: 40px;
}
.res1 a{
  display: flex !important;
  flex-direction: row-reverse;
  gap: 20px !important;
}
.res1 li{
  
}
.res1 img{
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  background: rgb(255, 205, 244);
}
.res1 p {
  margin-bottom: 0px !important;
}
.res ul {
  max-height: 300px;
  width: 290px;
  overflow-y: auto;
  padding: 30px 30px 10px 30px;
  /*box-shadow: 0 9px 8px -3px rgba(64, 60, 67, 0.24),
    8px 0 8px -7px rgba(64, 60, 67, 0.24),
    -8px 0 8px -7px rgba(64, 60, 67, 0.24);*/
}
.ais-Autocomplete {
  height: 46px;
  position:relative !important;
  width: 300px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  border-radius: 24px;
}

#autocomplete{
  width: 300px;
  background: transparent !important;
}
#autocomplete input {
  width: 300px;
  border: 1px solid transparent;
  z-index: 9;
  position: relative;
}

#autocomplete input:focus {
  outline: none;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none;
  border-bottom: 1px solid #e8eaed;
  box-shadow: 0px !important;
}

#autocomplete {
  margin-bottom: 0px;
}

.ais-InstantSearch {
  display: flex !important;
}

.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
  width: 30% !important;
}
/*
.ais-SearchBox-input,
.ais-SearchBox-submit {
  display: none;
}*/
.right1-panel .ais-SearchBox-input,
.right1-panel .ais-SearchBox-submit {
  display: block;
}

.ais-SearchBox-input {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgb(255 255 255 / 90%) !important;
}

@media only screen and (max-width: 992px) {
}
</style>
